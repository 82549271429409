import React from 'react';
import { useSelector } from 'react-redux';

import { graphql } from 'gatsby';

import { MDXRenderer } from 'gatsby-plugin-mdx';

import { H1, ContactWrapper } from '../elements';

import { Container, Page, Seo } from '../components';

const Contact = ({ location, data }) => {
  const locale = useSelector((state) => state.localeString);
  const isDark = useSelector((state) => state.isDark);

  const linguine = require('../utils/lingua')(
    useSelector((state) => state.localeString)
  );
  let contactAndSales = linguine('ContactAndSales');

  return (
    <Container pathname={location.pathname} isDark={isDark} locale={locale}>
      <Seo />
      <ContactWrapper isDark={isDark}>
        <div className={'titleStatement'}>
          <H1 row={'3'}>{contactAndSales.title}</H1>
        </div>
        <div className={'MDX'}>
          <Page isDark={isDark}>
            <MDXRenderer>{data.mdx.body}</MDXRenderer>
          </Page>
        </div>
        <div className={'store'}></div>
      </ContactWrapper>
    </Container>
  );
};
export default Contact;
export const pageQuery = graphql`
  query ContactTemplateQuery($id: String!) {
    mdx(id: { eq: $id }) {
      body
    }
  }
`;
